import React from 'react';

export default function Home(props) {
  return (
    <React.Fragment>
      <div id="back-to-top-anchor" />
      <div>
        <main>Hello Lead Coders! GO!</main>
      </div>
    </React.Fragment>
  );
}
